import React from "react";

import Layout from "../components/organisms/Layout/Layout";
import PageWidth from "../components/organisms/PageWidth/PageWidth";
import SEO from "../components/atoms/SEO/SEO";
import { htmlEncode } from "../utils/html";
import Section from "../components/organisms/Section/Section";
import CartPage from "../components/organisms/Shop/CartPage/CartPage";

const Imprint = () => (
  <>
    <CartPage />
    <Layout>
      <SEO
        title="Impressum"
        description="Rechtliche Angaben zur Seite stadthonig.shop"
      />
      <Section>
        <PageWidth>
          <h1>Impressum</h1>
          <p>
            Diese Domain wird betrieben von Karl Ludwig Weise, Grevenweg 80,
            20537 Hamburg, Deutschland,{" "}
            <span
              dangerouslySetInnerHTML={{
                __html: htmlEncode("ludwig@stadthonig.shop"),
              }}
            />
            , USt-ID: DE224733454.
          </p>
          <p>
            Alle Angaben ohne Gewähr. Änderungen und Irrtümer vorbehalten.
            Angebote sind freibleibend. Alle Rechte vorbehalten.
            Vervielfältigung/Verwendung ohne schriftliche Genehmigung nicht
            gestattet.
          </p>
        </PageWidth>
      </Section>
    </Layout>
  </>
);

export default Imprint;
